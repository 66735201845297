import { languages } from '../../../i18n/languages'

export default {
  [languages.en]: {
    masonry: {
      office: 'A picture of the inside of our Melbourne office, with employees standing at their desks.',
      courtyard: "Envato's Melbourne Office outdoor courtyard",
      employees: 'Our content team in Guadalajara',
      mexico: 'A picture of some work stations in our Envato Mexico office in Guadalajara',
      meeting: 'Collaborative spaces in our Melbourne office',
      basketball: 'Our Melbourne office courtyard enjoys a basketball area',
      fire: 'Our Mexico team members around a campfire on a work trip',
      painting: 'Our office walls are emblazoned with amazing artwork by our talented creatives',
      yoga: 'Our employees enjoying some wellbeing activities in our Guadalajara office'
    }
  },
  [languages.es]: {
    masonry: {
      office:
        'Interior de las instalaciones de Envato en Melbourne, con miembros del equipo de pie en sus escritorios.',
      courtyard: 'Patio exterior en Envato Melbourne',
      employees: 'Nuestro equipo de contenido en Guadalajara.',
      mexico: 'Foto de algunos puestos de trabajo en nuestra oficina de Envato México en Guadalajara.',
      meeting: 'Espacios colaborativos en nuestra oficina de Melbourne.',
      basketball: 'Nuestra oficina de Melbourne disfruta de un área de basketball.',
      fire: 'Nuestro equipo de México alrededor de una fogata en un viaje de la empresa.',
      painting: 'Las paredes de nuestra oficina están adornadas con increíbles obras de nuestros creativos.',
      yoga: 'Nuestros empleados disfrutando de algunas actividades de bienestar en nuestra oficina de Guadalajara.'
    }
  }
}

import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import { useTranslation } from '../../i18n/index'
import Figma from '../figma/figma'
import { frontdoorPaths } from '../../routing/paths'
import translations from './careers_intro.translations'
import styles from './careers_intro.module.css'

const CareersIntro = () => {
  const t = useTranslation(translations)

  return (
    <div className={styles.root}>
      <Figma.Layout type='fiveSixVAlignTop'>
        <div className={styles.left}>{t.left}</div>
        <div className={styles.right}>
          <p className={styles.text}>{t.right}</p>
          <div className={styles.links}>
            <span>
              <AnchorWithArrow href={frontdoorPaths.aboutCompany} trackingId='careers_our_story'>
                {t.ourStoryLink}
              </AnchorWithArrow>
            </span>
            <span className={styles.secondLink}>
              <AnchorWithArrow
                href='https://www.envato.com/blog/envato-impact-statement-2022/'
                trackingId='careers_2022_impact_statement'
              >
                {t.impactStatementLink}
              </AnchorWithArrow>
            </span>
          </div>
        </div>
      </Figma.Layout>
    </div>
  )
}

export default CareersIntro

import React from 'react'
import { languages } from '../../i18n/languages'

export default {
  [languages.en]: {
    left: <>Envato is a world-leading online community for creative assets and tools</>,
    right: (
      <>
        We believe in providing genuine opportunities to create, earn and grow for creators around the world, and strive
        to make creative success accessible and achievable for all, both independently and as part of a global community
      </>
    ),
    ourStoryLink: 'See our story',
    impactStatementLink: 'See our 2022 impact statement'
  },
  [languages.es]: {
    left: <>Envato es una comunidad online global líder en assets y herramientas digitales.</>,
    right: (
      <>
        Creemos en brindar oportunidades para crear, crecer y generar ganancias para nuestros creadores alrededor del
        mundo. Nos esfrozamos por hacer el éxito creativo accesible y alcanzable para todos, tanto de forma
        independiente como siendo parte de una comunidad global.
      </>
    ),
    ourStoryLink: 'Ve nuestra historia',
    impactStatementLink: 'Ve nuestra declaración de impacto del 2022'
  }
}

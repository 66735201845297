import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import Figma from '../figma/figma'
import { frontdoorAnchors } from '../../routing/paths'
import { useTranslation } from '../../i18n/index'
import translations from './careers_header.translations'
import hero from './hero.png'
import styles from './careers_header.module.css'

const CareersHeader = () => {
  const t = useTranslation(translations)

  return (
    <Figma.Section>
      <Figma.Layout type='sixFiveMobileReversed'>
        <div>
          <Figma.Text type='preheading' block>
            {t.preheading}
          </Figma.Text>
          <h1>
            <Figma.Text type='mega' block>
              {t.heading}
            </Figma.Text>
          </h1>
          <Figma.Text type='standard' block>
            {t.body}
          </Figma.Text>
          <div className={styles.anchor}>
            <AnchorWithArrow
              href={frontdoorAnchors.careersPositions}
              arrowDirection='down'
              trackingId='careers_positions'
            >
              {t.cta}
            </AnchorWithArrow>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={hero} className={styles.img} alt={t.imageAlt} />
        </div>
      </Figma.Layout>
    </Figma.Section>
  )
}

export default CareersHeader

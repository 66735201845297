import { languages } from '../../i18n/languages'

export const locations = {
  all: 'All locations',
  australia: 'Australia & New Zealand',
  mexico: 'Mexico'
}

export default {
  [languages.en]: {
    heading: 'Open Roles',
    locationFilter: 'All Locations',
    departmentFilter: 'All Departments',
    noPositionsMessage: {
      [locations.australia]: 'There are currently no open roles in Australia & New Zealand.',
      [locations.mexico]: 'There are currently no open roles in Mexico.',
      [locations.all]: 'There are currently no open roles.'
    },
    errorMessage: 'Sorry, something went wrong.'
  },
  [languages.es]: {
    heading: 'Vacantes',
    locationFilter: 'Todas las ubicaciones',
    departmentFilter: 'Todos los departamentos',
    noPositionsMessage: {
      [locations.australia]: 'No hay vacantes.',
      [locations.mexico]: 'No hay vacantes.',
      [locations.all]: 'No hay vacantes.'
    },
    errorMessage: 'Algo salió mal.'
  }
}

import React from 'react'
import { Loading } from '@envato/ui'
import { ErrorBoundary } from 'react-error-boundary'

const Component = React.lazy(() => import('./values_section'))

const ValuesSection = (props) => (
  <ErrorBoundary fallback={<Loading takingTooLong />}>
    <React.Suspense fallback={<Loading />}>
      <Component {...props} />
    </React.Suspense>
  </ErrorBoundary>
)

export default ValuesSection

import React from 'react'
import { Loading } from '@envato/ui'
import { ErrorBoundary } from 'react-error-boundary'

const Component = React.lazy(() => import('./open_positions'))

const OpenPositions = (props) => (
  <ErrorBoundary fallback={<Loading takingTooLong />}>
    <React.Suspense fallback={<Loading />}>
      <Component {...props} />
    </React.Suspense>
  </ErrorBoundary>
)

export { locations } from './open_positions.translations'
export default OpenPositions

import React from 'react'
import { languages } from '../../i18n/languages'

export default {
  [languages.en]: {
    preheading: 'Careers',
    heading: 'Your work, your impact',
    body: (
      <>
        At Envato, you have freedom to pursue more meaningful work which makes a real impact on our community. Come and
        find your place within our global community of talented and passionate people who strive for purpose and profit.
      </>
    ),
    cta: 'See Open Roles',
    imageAlt: 'A group photo of the Envato Melbourne team in the office courtyard'
  },
  [languages.es]: {
    preheading: 'Profesiones',
    heading: 'Tu trabajo, tu impacto',
    body: (
      <>
        En Envato, tienes la libertad de trabajar en cosas significativas que tenienen impacto real en nuestra
        comunidad. Ven y encuentra tu lugar en nuestra comunidad global de profesionales talentosos y apasionados, que
        tienen un propósito y buscan generar ganancias.
      </>
    ),
    cta: 'Ve Nuestras Vacantes',
    imageAlt: 'Foto grupal del equipo de trabajo en Melbourne en las instalaciones de Envato'
  }
}
